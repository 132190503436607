import React, { useState } from "react";
import {
	Button,
	Grid,
	MenuItem,
	ListItemText,
	ListItemIcon,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArrowRight from "@mui/icons-material/ArrowRightSharp";
import CircularProgress from "@mui/material/CircularProgress";
import { AlertBox } from "../Alert/Alert";
import { DataContext } from "../../../App";

export default function DownloadDocument({ name, pageType, id, isDisabled }) {

	if (!name || !pageType || !id) return;

	const context = React.useContext(DataContext);
	const [state, setState] = useState({
		isLoading: false,
		status: false,
		alertMessage: "",
		alertSeverity: "",
		showAlert: false,
		isDisabled: isDisabled || false,
	});

	const getDocument = async () => {

		try {

			setState(previousState => ({
				...previousState,
				isLoading: true,
				status: false,
				alertMessage: "",
				alertSeverity: "",
				showAlert: false,
			}));

			let responsePromise;
			if (name === "ASN Document") responsePromise = context.dataProvider.getASNPaperwork(id);
			if (name === "Commercial Invoice") responsePromise = context.dataProvider.getCommercialInvoice(id);

			const response = await responsePromise;

			if (response.data && response.data.data) {
				const win = window.open();
				win.document.write(
					"<iframe"
					+ " src=\"data:application/pdf;base64," + response.data.data + "\""
					+ " title=\"DOCUMENT_\"" + name
					+ " name=\"DOCUMENT_\"" + name
					+ " style=\"border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;\""
					+ " loading=\"eager\""
					+ " referrerpolicy=\"no-referrer\""
					+ " allowfullscreen>"
					+ "</iframe>"
				);
			}
		} catch (error) {
			setState(previousState => ({
				...previousState,
				alertMessage: `Error downloading ${name}, please try again later or contact support@bezos.ai`,
				alertSeverity: "error",
				showAlert: true,
				status: true,
			}));
		} finally {
			setState(previousState => ({
				...previousState,
				isLoading: false,
			}));
		}
	};

	const DownloadDocumentButton = () => (
		<Grid item xs="auto">
			<Button
				variant="outlined"
				color="secondary"
				onClick={() => getDocument()}
				disabled={state.isDisabled}
			>
				View {name} {state.isLoading && <CircularProgress size={20} sx={{ ml: 2 }} />}
			</Button>
			{state.status &&
				<AlertBox sx={{ mt: 1 }} severity="error" message={state.alertMessage} />
			}
		</Grid>
	);

	const DownloadDocumentMenuItem = () => (
		<>
			<MenuItem onClick={() => getDocument()}
			>
				<ListItemIcon>
					{state.isLoading ? <CircularProgress size={20} /> : <PictureAsPdfIcon />}
				</ListItemIcon>
				<ListItemText>
					View {name}
				</ListItemText>
				<ArrowRight />
			</MenuItem>
			{state.status &&
				<AlertBox sx={{ ml: 1, mr: 1 }} severity="error" message={state.alertMessage} />
			}
		</>
	);

	return (
		<>
			{pageType === "summary" &&
				<DownloadDocumentMenuItem />
			}

			{pageType === "detail" &&
				<DownloadDocumentButton />
			}
		</>
	);

}
